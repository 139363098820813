export enum DynamicRoutes {
  privacyPolicy = "/privacy-policy/",
  privacyPolicyJp = "https://www.dai-ichi-life.co.jp/personal_date/index.html",
  rewardsPolicy = "/rewards-policy/",
  complaints = "https://resource.yulife.com/za/complaints",
  cookiePolicy = "/cookie-policy/",
  endUserLicenseAgreementPolicyUS = "/end-user-license-agreement-policy/",
  endUserLicenseAgreementPolicyUK = "/yulife-app-eula/",
  websiteTermsAndConditions = "/website-terms-and-conditions/",
  blogCategoryNews = "/blog/category/news/",
}
