export const QUOTE_BUTTON_ID = "quote-button";

export const GET_A_QUOTE_FORM_ID = "get-a-quote-form";
export const GET_A_QUOTE_SUBMIT_ID = "get-a-quote-submit";

export const GET_A_CALLBACK_FORM_ID = "get-a-callback-form";
export const GET_A_CALLBACK_SUBMIT_ID = "get-a-callback-submit";

export const IFRAME_FORM_ID = "iframe-form";
export const IFRAME_SUBMIT_ID = "iframe-submit";

export const NEWSLETTER_SIGN_UP_FORM_ID = "newsletter-signup-form";
export const NEWSLETTER_SIGN_UP_BUTTON_ID = "newsletter-signup-button";
export const NEWSLETTER_SIGN_UP_BUTTON_MOBILE_ID = "newsletter-signup-button-mobile";

export const BLOG_SIGN_UP_FORM_ID = "blog-signup-form";
export const BLOG_SIGN_UP_BUTTON_ID = "blog-signup-button";

export const CURRENT_YEAR = new Date().getFullYear();
export const YULIFE_DATA = `YuLife data ${CURRENT_YEAR}`;

export const HUBSPOT_APP_URL = "https://app.hubspot.com";
export const HUBSPOT_MEETINGS_URL = "https://meetings.hubspot.com";
export const HUBSPOT_FORMS_API_URL = "https://api-eu1.hsforms.com";

export const HUBSPOT_COOKIES_PROVIDER = "hubspot.com";
export const HUBSPOT_FORMS_COOKIES_PROVIDER = "forms.hsforms.com";

export const HUBSPOT_IFRAME_CLASS = "meetings-iframe-container";
export const HUBSPOT_SME_IFRAME_DATA = `${HUBSPOT_MEETINGS_URL}/yulife2023/sme-insurance-meeting-round-robin?embed=true`;
export const HUBSPOT_LARGE_ENTERPRISE_IFRAME_DATA = `${HUBSPOT_MEETINGS_URL}/yulife2024/large-meeting-round-robin?embed=true`;

export const SPECULATIVE_JOB_ID = "3101322";

export const HUBSPOT_FORM_LOCAL_STORAGE_CONTACT_DETAILS = "hubspot_form_contact_details";
export const HUBSPOT_FORM_LOCAL_STORAGE_SAVED_FIELDS = "hubspot_form_saved_fields";
export const SIGNUP_SUBMITTED_LOCAL_STORAGE = (location: string) =>
  `${location.toUpperCase()}_SIGNUP_SUBMITTED`;
export const GDPR_LOCAL_STORAGE = `gdpr`;
export const SIGNUP_DISMISSED_LOCAL_STORAGE = "SIGNUP_DISMISSED";

export const GENERIC_RESOURCES_ERROR = "No resources found";
export const GENERIC_CATEGORIES_ERROR = "No categories found";

export const NUMBER_OF_POSTS_TO_FETCH = 7;

export const TEST_DOMAIN_EMAILS = ["email", "test", "example"];

// TODO - this will need to re-added at some point for use on the YuLife domain (rather than the Dai-ichi domain)
// export const JP_IFRAME_FORM_SUCCESS_REDIRECT = "https://japan.yulife.com/thankyou";

export const JP_IFRAME_FORM_PRODUCT_DOC_THANK_YOU =
  "https://well-being.dai-ichi-life.co.jp/08042025_test_product-doc-thankyou";
export const JP_IFRAME_FORM_SPEAK_TO_TEAM_THANK_YOU =
  "https://well-being.dai-ichi-life.co.jp/08042025_test_speak-to-team-thankyou";

export const TRUST_CENTER_URL = "https://trust.yulife.com/";

export const PERSONAL_DOMAIN_EMAILS = [
  "yahoo",
  "hotmail",
  "aol",
  "msn",
  "wanadoo",
  "orange",
  "comcast",
  "live",
  "rediffmail",
  "free",
  "gmx",
  "web",
  "yandex",
  "ymail",
  "libero",
  "outlook",
  "uol",
  "bol",
  "mail",
  "cox",
  "sbcglobal",
  "sfr",
  "verizon",
  "googlemail",
  "ig",
  "bigpond",
  "terra",
  "neuf",
  "alice",
  "rocketmail",
  "att",
  "laposte",
  "facebook",
  "bellsouth",
  "charter",
  "rambler",
  "tiscali",
  "shaw",
  "sky",
  "earthlink",
  "optonline",
  "freenet",
  "t-online",
  "aliceadsl",
  "virgilio",
  "home",
  "qq",
  "telenet",
  "me",
  "voila",
  "planet",
  "tin",
  "ntlworld",
  "arcor",
  "frontiernet",
  "hetnet",
  "zonnet",
  "club-internet",
  "juno",
  "optusnet",
  "blueyonder",
  "bluewin",
  "skynet",
  "sympatico",
  "windstream",
  "mac",
  "centurytel",
  "chello",
  "aim",
  "icloud",
];

export const NON_ENGLISH_LOCALES = ["jp"];
