import { useRouter } from "next/router";
import React from "react";
import { useEffect } from "react";

const UserwayWidget = () => {
  const router = useRouter();

  // can be extended to remove the userway widget from any route
  const excludedRoutes = React.useMemo(
    () => [
      "/iframe/jp-get-a-quote",
      "/iframe/jp-product-doc-download",
      "/iframe/group-life-form",
      "/iframe/quote-form",
    ],
    [],
  );

  useEffect(() => {
    if (excludedRoutes.includes(router.pathname)) {
      return;
    }

    const script = document.createElement("script");
    script.setAttribute("data-position", "5");
    script.setAttribute("data-color", "#E30D76");
    script.setAttribute("data-account", "8OtbRks5mk");
    script.setAttribute("src", "https://cdn.userway.org/widget.js");
    document.body.appendChild(script);

    // cleanup function to remove the widget if the user navigates to any excluded page

    return () => {
      const userwayWidget = document.querySelector('iframe[id^="userway"]')?.parentElement;
      if (userwayWidget) {
        document.body.removeChild(userwayWidget);
      }
      const scripts = document.querySelectorAll('script[src="https://cdn.userway.org/widget.js"]');
      scripts.forEach((script) => script.parentNode?.removeChild(script));
    };
  }, [router.pathname, excludedRoutes]);

  return (
    <noscript>
      Please ensure Javascript is enabled for purposes of{" "}
      <a href="https://userway.org">website accessibility</a>
    </noscript>
  );
};

export default UserwayWidget;
